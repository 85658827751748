import { IconButton, Tooltip, useStyleConfig } from '@chakra-ui/react';
import { UserLocationIcon } from '../../config/icons';
import React, { FC } from 'react';

interface GeolocationInputButtonProps {
  onGetGeolocation: () => void;
}

const GeolocationInputButton: FC<GeolocationInputButtonProps> = ({
  onGetGeolocation,
}) => {
  const css = useStyleConfig('GeolocationInputButton', {});

  return (
    <Tooltip
      zIndex={99}
      hasArrow
      label="Aktuellen Standort ermitteln"
      placement="top"
    >
      <IconButton
        aria-label="Aktuellen Standort ermitteln"
        icon={<UserLocationIcon color="gray.600" />}
        onClick={onGetGeolocation}
        sx={css}
      />
    </Tooltip>
  );
};

export default GeolocationInputButton;
