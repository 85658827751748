import {
  Box,
  BoxProps,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LocationIcon } from '../../config/icons';
import { GeocoderFeature, GeocoderResult } from '../../types/global-types';
import { geocoderAxiosInstance } from '../../utils/api';
import { useLazyQuery } from '@apollo/client';
import {
  AutoCompleteQueryQueryVariables,
  GeocodeResultFeature,
  Query,
  TermCity,
  TermCountry,
  TermPostcode,
  TermRegion,
} from '../../generated/types';
import { AUTOCOMPLETE_QUERY } from '../../queries';
import { useStore } from '../../store/provider';
import { useRouter } from 'next/router';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import GeolocationInputButton from './GeolocationInputButton';

const geocoderFeatureTransform = (
  feature: GeocodeResultFeature | GeocoderFeature
): TransformedGeocoderFeature => {
  return {
    country: feature.properties.country || null,
    name:
      feature.properties.name ||
      feature.properties.city ||
      feature.properties.postcode ||
      '',
    postcode: feature.properties.postcode || null,
    city: feature.properties.city || null,
    state: feature.properties.state || null,
    position: feature.geometry.coordinates,
  };
};

const transformedGeocoderFeatureToMetaString = (
  feature: TransformedGeocoderFeature
) => {
  const parts = [
    feature.postcode,
    feature.city,
    feature.state,
    feature.country,
  ].filter((i) => i);

  return parts.join(', ');
};

export interface TransformedGeocoderFeature {
  country: string | null;
  name: string;
  city: string | null;
  state: string | null;
  position: number[];
  postcode: string | null;
}

interface BetterPlaceAutocompleteProps {
  id: string;
  initialValue?: string;
  placeholder: string;
  onSelectItem: (item: TransformedAutocompleteData) => void;
  onChangeQuery?: (text: string) => void;
  category: string;
  withSearchButton?: boolean;
  onClickSearch?: (inputValue?: string) => void;
  radius?: boolean;
  radiusValue?: string;
  onChangeRadius?: (val: string) => void;
  inputProps?: InputProps;
  hideLeftIcon?: boolean;
}

export type AutoCompleteTermType =
  | TermCountry
  | TermPostcode
  | TermCity
  | TermRegion;

export type TransformedAutocompleteData = {
  name: string;
  type: string;
  data:
    | TermCountry
    | TermPostcode
    | TermCity
    | TermRegion
    | GeocodeResultFeature
    | GeocoderFeature
    | AutocompleteResultItem;
};

interface AutocompleteResultItem {
  id: number;
  title: string;
  url: string;
}

export const getNameFromGeocoder = (
  data: GeocodeResultFeature | GeocoderFeature
) => {
  return (
    data.properties.name ||
    data.properties.city ||
    data.properties.postcode ||
    ''
  );
};

const getGroupName = (type: string) => {
  switch (type) {
    case 'result':
      return 'Direkte Treffer';

    case 'cities':
      return 'Städte';

    case 'countries':
      return 'Länder';

    case 'postcodes':
      return 'PLZ';

    case 'regions':
      return 'Regionen';

    case 'geocode':
      return 'Umkreissuche';
  }

  return type;
};

const transformAutocompleteResult = (
  data: Query
): TransformedAutocompleteData[] => {
  const result: TransformedAutocompleteData[] = [];

  if (data.autocomplete) {
    data.autocomplete.result.items.forEach((item) => {
      result.push({
        name: item.title,
        type: 'result',
        data: item,
      });
    });

    data.autocomplete.countries.items.forEach((item) => {
      result.push({
        name: item.name,
        type: 'countries',
        data: item,
      });
    });

    data.autocomplete.regions.items.forEach((item) => {
      result.push({
        name: item.name,
        type: 'regions',
        data: item,
      });
    });

    // data.autocomplete.cities.items.forEach((item) => {
    //   result.push({
    //     name: item.name,
    //     type: 'cities',
    //     data: item,
    //   });
    // });

    // data.autocomplete.postcodes.items.forEach((item) => {
    //   result.push({
    //     name: item.name,
    //     type: 'postcodes',
    //     data: item,
    //   });
    // });

    data.autocomplete.geocodeResults?.features.forEach((feature) => {
      result.push({
        name: getNameFromGeocoder(feature),
        type: 'geocode',
        data: feature,
      });
    });
  }

  return result;
};

type BetterPlaceAutocompleteBoxProps = BetterPlaceAutocompleteProps & BoxProps;

const BetterPlaceAutocomplete: React.FC<BetterPlaceAutocompleteBoxProps> = ({
  id,
  placeholder,
  onSelectItem,
  initialValue,
  onChangeQuery,
  category,
  withSearchButton,
  onClickSearch,
  radius,
  radiusValue,
  onChangeRadius,
  inputProps,
  hideLeftIcon,
  ...props
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState<
    TransformedAutocompleteData[]
  >([]);
  const [query, setQuery] = useState(initialValue);
  const [userLocation, setUserlocation] = useState<null | {
    latitude: number;
    longitude: number;
  }>(null);
  const [getLazyData, { data, loading }] = useLazyQuery<
    Query,
    AutoCompleteQueryQueryVariables
  >(AUTOCOMPLETE_QUERY);
  const store = useStore();
  const router = useRouter();
  const [showRadius, setShowRadius] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetch(query, userLocation);
    }, 500);
  }, [query, userLocation]);

  const fetch = useCallback(
    async (query, userLocation) => {
      if (query === 'Ort auf der Karte' || userLocation !== null) {
        return;
      }

      if (query && query.length >= 3) {
        getLazyData({
          variables: {
            category,
            frontend: store.config.frontendName,
            searchQuery: query,
          },
        });
      }
    },
    [getLazyData]
  );

  useEffect(() => {
    if (initialValue) {
      setAutocompleteData([]);
      setInputValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (data) {
      setAutocompleteData(transformAutocompleteResult(data));
    } else {
      setAutocompleteData([]);
    }
  }, [data]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    closeMenu,
    inputValue,
  } = useCombobox<TransformedAutocompleteData>({
    defaultInputValue: query || '',
    id,
    items: autocompleteData,
    onInputValueChange: (changes) => {
      if (changes.inputValue) {
        if (
          !changes.selectedItem ||
          (changes.selectedItem && changes.selectedItem.type !== 'result')
        ) {
          setUserlocation(null);
          setQuery(changes.inputValue);
          onChangeQuery && onChangeQuery(changes.inputValue);
        }
      }
    },
    itemToString: (item) => {
      return item.name;
    },
    onSelectedItemChange: (state) => {
      if (state.selectedItem) {
        if (state.selectedItem.type === 'result') {
          const resultData = state.selectedItem.data as AutocompleteResultItem;

          if (resultData && resultData.url) {
            router.push(resultData.url);
          }
        } else {
          setUserlocation(null);
          onSelectItem(state.selectedItem);
        }

        closeMenu();
      }
    },
  });

  const onGetGeolocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true);

      navigator.geolocation.getCurrentPosition(
        async (e) => {
          // Geocode
          try {
            const result = await geocoderAxiosInstance.get<GeocoderResult>(
              '/reverse',
              {
                params: {
                  lat: e.coords.latitude,
                  lon: e.coords.longitude,
                },
              }
            );

            if (result.data && result.status === 200) {
              if (result.data.features && result.data.features.length > 0) {
                const newQuery = `Dein Standort: ${result.data.features[0].properties.city}`;

                setInputValue(newQuery);
                setQuery(newQuery);
                setUserlocation({
                  latitude: e.coords.latitude,
                  longitude: e.coords.longitude,
                });
                const transformedFeature = geocoderFeatureTransform(
                  result.data.features[0]
                );
                transformedFeature.position = [
                  e.coords.longitude,
                  e.coords.latitude,
                ];
                onSelectItem({
                  name: getNameFromGeocoder(result.data.features[0]),
                  type: 'geocode',
                  data: result.data.features[0],
                });
                onChangeQuery && onChangeQuery(newQuery);
              }
            } else {
              toast({
                title: 'Fehler',
                description: 'Der Standort konnte nicht ermittelt werden.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
                position: 'top',
              });
            }
          } catch (err) {}

          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          toast({
            title: 'Fehler',
            description:
              'Der Standort konnte nicht ermittelt werden. Bitte schalte die Standort-Funktionalität im Browser frei.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      );
    } else {
      toast({
        title: 'Fehler',
        description:
          'Der Standort konnte nicht ermittelt werden. Dein Browser unterstützt diese Funktion nicht.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  let previousGroup: any = null;

  let rightElementWidth = '2.5rem';

  if (withSearchButton) {
    rightElementWidth = '6.5rem';

    if (radius) {
      rightElementWidth = '16.5rem';
    }
  }

  return (
    <Box flex={1} pos="relative" {...props}>
      <Box {...getComboboxProps()}>
        <InputGroup flex={1}>
          {!hideLeftIcon && (
            <InputLeftElement
              zIndex={2}
              children={<LocationIcon color="gray.300" />}
            />
          )}
          <Input
            name="query"
            placeholder={placeholder}
            {...inputProps}
            {...getInputProps()}
          />

          <InputRightElement
            marginRight="1px"
            width={rightElementWidth}
            zIndex={1500}
            children={
              <Flex alignItems="center">
                {radius && (
                  <Flex alignItems="center" width="10rem" pos="relative">
                    <Menu gutter={0} placement="bottom-end">
                      <MenuButton
                        size="sm"
                        fontSize="md"
                        variant="unstyled"
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        Umkreis {radiusValue} km
                      </MenuButton>
                      <MenuList fontSize="md">
                        <MenuOptionGroup
                          onChange={onChangeRadius}
                          defaultValue={radiusValue}
                          type="radio"
                        >
                          <MenuItemOption value="10">10 km</MenuItemOption>
                          <MenuItemOption value="30">30 km</MenuItemOption>
                          <MenuItemOption value="50">50 km</MenuItemOption>
                          <MenuItemOption value="100">100 km</MenuItemOption>
                          <MenuItemOption value="200">200 km</MenuItemOption>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </Flex>
                )}

                {isLoading || loading ? (
                  <Flex alignItems="center" justifyContent="center" w="2.5rem">
                    <Spinner />
                  </Flex>
                ) : (
                  <GeolocationInputButton onGetGeolocation={onGetGeolocation} />
                )}

                {withSearchButton && (
                  <IconButton
                    onClick={() => onClickSearch(inputValue)}
                    paddingX={2}
                    minWidth="4rem"
                    variant="unstyled"
                    bg="searchbar.buttonBg"
                    aria-label="Suchen"
                    color="#fff"
                    borderRadius="3xl"
                    icon={<SearchIcon />}
                    _hover={{
                      bg: 'brand.700',
                      color: '#fff',
                    }}
                  />
                )}
              </Flex>
            }
          />
        </InputGroup>
      </Box>
      <Box
        as="ul"
        pos="absolute"
        listStyleType="none"
        left={0}
        right={0}
        top="100%"
        bg="#fff"
        display={!isOpen ? 'none' : 'block'}
        border="1px"
        borderColor="gray.200"
        maxH="300px"
        overflowY="auto"
        shadow="md"
        zIndex={9999}
        {...getMenuProps()}
      >
        {isOpen &&
          autocompleteData.map((item, index) => {
            let shouldRenderGroup = previousGroup !== item.type;

            previousGroup = item.type;

            return (
              <React.Fragment key={`${item}${index}`}>
                {shouldRenderGroup && (
                  <Box
                    as="li"
                    bg="gray.200"
                    paddingY={2}
                    paddingX={4}
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {getGroupName(item.type)}
                  </Box>
                )}
                <Box
                  as="li"
                  paddingY={2}
                  paddingX={4}
                  bg={highlightedIndex === index ? 'blue.100' : '#fff'}
                  cursor="pointer"
                  {...getItemProps({ item, index })}
                >
                  <Box>{item.name}</Box>
                  {item.type === 'geocode' && (
                    <Box fontSize="sm" color="gray.500">
                      {transformedGeocoderFeatureToMetaString(
                        geocoderFeatureTransform(
                          item.data as GeocodeResultFeature
                        )
                      )}
                    </Box>
                  )}
                </Box>
              </React.Fragment>
            );
          })}
      </Box>
    </Box>
  );
};

export default BetterPlaceAutocomplete;
